import _ from 'lodash';
import 'bootstrap';
import 'scss/main.scss';
import AOS from 'aos';
import LazyLoad from "vanilla-lazyload";

var lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
});

document.addEventListener("DOMContentLoaded", function(event) {
  AOS.init({ disable: 'mobile' });
  lazyLoadInstance.update();
});

var nav = document.getElementById('mainNav');
window.onscroll = function () { 
  "use strict";
  if (document.body.scrollTop >= 200 || document.documentElement.scrollTop >= 200 ) {
    nav.classList.add("bg-dark");
    nav.classList.remove("bg-transparent");
  } 
  else {
    nav.classList.add("bg-transparent");
    nav.classList.remove("bg-dark");
  }
};

function preloader() 
{
  // counter
  var i = 0;

  // create object
  let imageObj = new Image();

  // set image list
  let images = new Array();
  images[0]="assets/img/hexagonButton/active.svg"
  images[1]="assets/img/hexagonButton/hover.svg"
  images[2]="assets/img/hexagonButton/default.svg"
  images[0]="assets/img/hexagonButtonDark/active.svg"
  images[1]="assets/img/hexagonButtonDark/hover.svg"
  images[2]="assets/img/hexagonButtonDark/default.svg"

  // start preloading
  for(i=0; i<=3; i++) 
  {
    imageObj.src=images[i];
  }
}
preloader()